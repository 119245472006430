import React, { createContext, useState, useEffect } from "react";
import {
  clearLocalData,
  getLocalData,
  storeLocalData,
} from "../services/storage";
import { User } from "../types/user";
import { STORAGE_KEYS } from "../services/storage";
import instanceAXIOS from "../services/api";

import Toast from "react-native-toast-message";
import queryClient from "../services/reactQuery";
import { Linking, Platform } from "react-native";
import { OneSignal, LogLevel } from "react-native-onesignal";
import registerForPushNotifications from "../services/pushNotifications";
import * as RootNavigation from "../navigation/RootNavigation";
import { HubConnectionBuilder } from "@microsoft/signalr";

export type InputLoginData = {
  Email: string;
  Password: string;
};
interface ContextProps {
  signed: boolean;
  login: (data: InputLoginData) => void;
  logout: () => void;
  tabVisible: boolean;
  setTabVisible: (value: boolean) => void;
  user: User | null;
  getUnreadCount: () => void;
  assignedToModal: any;
  setAssignedToModal: any;
  newNoteModal: any;
  setNewNoteModal: any;
  unreadCount: number;
  connection: any;
  setUnreadCount: any;
}

const Context = createContext({} as ContextProps);
export default Context;

export const ContextProvider: React.FC = ({ children }) => {
  const [signed, setSigned] = useState(null);
  const [user, setUser] = useState<User | null>(null);
  const [assignedToModal, setAssignedToModal] = useState(null);
  const [newNoteModal, setNewNoteModal] = useState(null);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [connection, setConnection] = useState<any>(null);
  const [tabVisible, setTabVisible] = useState(true);

  useEffect(() => {
    checkAuth();
    if (Platform.OS !== "web") {
      OneSignal.Debug.setLogLevel(LogLevel.Verbose);
      OneSignal.initialize("7a6ad45b-9f08-4392-a70d-0c9f1ee9447f");
      OneSignal.Notifications.requestPermission(true);
    }

    return () => {
      if (Platform.OS !== "web") {
        OneSignal.Notifications.removeEventListener("click", () => {});
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      if (connection) {
        connection.off("ReceiveMessage");
        connection.stop();
      }
    };
  }, [connection]);

  const oneSignal = async (uuid: string) => {
    registerForPushNotifications(uuid);
    OneSignal.Notifications.addEventListener("click", (event) => {
      const name = event?.notification?.additionalData?.client_name;
      const orderNumber = event?.notification?.additionalData?.order_number;
      const orderUuid = event?.notification?.additionalData?.order_uuid;
      const unread = false;
      getLocalData(STORAGE_KEYS.KEY_USER).then((user) => {
        if (user?.role === "client") {
          setTimeout(() => {
            Linking.openURL(
              `pressedfloral://ClientBottomTabNavigator/ClientChatNavigator/Conversation?orderUuid=${orderUuid}&name=${name}&orderNumber=${orderNumber}&unread=${unread}&fromNotification=true`
            );
          }, 200);
        } else {
          setTimeout(() => {
            Linking.openURL(
              `pressedfloral://Root/ChatNav/Conversation?orderUuid=${orderUuid}&name=${name}&orderNumber=${orderNumber}&unread=${unread}&fromNotification=true`
            );
          }, 200);
        }
      });
    });
  };

  const checkAuth = async () => {
    const token = await getLocalData(STORAGE_KEYS.KEY_TOKEN);
    const _user = await getLocalData(STORAGE_KEYS.KEY_USER);
    if (token && _user) {
      setUser(_user);
      setSigned(true);
      if(Platform.OS !== "web"){
        oneSignal(_user.uuid);
      }
      getUnreadCount();
      connectSignalR(token, _user);
    } else {
      setSigned(false);
    }
  };

  const login = async (data: InputLoginData) => {
    instanceAXIOS
      .post("/Authentication/Login", data)
      .then((response) => {
        if (Platform.OS === "web") {
          if (response.data.user.role !== "client") {
            Toast.show({
              type: "error",
              props: {
                message: "Client login only",
              },
            });
            return;
          }
        }
        storeLocalData(STORAGE_KEYS.KEY_TOKEN, response.data.jwt);
        storeLocalData(STORAGE_KEYS.KEY_USER, response.data.user);
        setUser(response.data.user);
        setSigned(true);
        oneSignal(response.data.user.uuid);
        connectSignalR(response?.data?.jwt, response?.data?.user);
      })
      .catch((e) => {
        Toast.show({
          type: "error",
          props: {
            message: "Invalid Email or Password",
          },
        });
        setSigned(false);
      });
  };

  const logout = async () => {
    await clearLocalData();
    queryClient.clear();
    OneSignal.logout();
    setSigned(false);
    setUser(null);
    setConnection(null);
    registerForPushNotifications(null);
  };

  const getUnreadCount = async () => {
    instanceAXIOS
      .get("/OrderConversation/UnreadMessageCount")
      .then((response) => {
        setUnreadCount(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const connectSignalR = async (token: string, _user: User) => {
    const hubUrl = "https://pressedfloralapi.azurewebsites.net/messagehub";

    const _connection = new HubConnectionBuilder()
      .withUrl(`${hubUrl}?access_token=${token}`)
      .withAutomaticReconnect()
      .build();

    try {
      await _connection.start();
      console.log("Connected to the SignalR hub.");

      // Set up event listener here
      _connection.on("ReceiveMessage", (data) => {
        if (data?.userUuid !== _user?.uuid) {
          Toast.show({
            type: "success",
            props: {
              message: `New message from ${data.firstName} ${data.lastName}`,
            },
          });
        }
        getUnreadCount();
      });
      _connection.on("AddUserToOrderConversation", (data) => {
        if (data?.userUuid === _user?.uuid) {
          _connection.invoke("AddUserToGroup", data?.orderUuid);
        }
      });
      _connection.on("RemoveUserFromOrderConversation", (data) => {
        if (data?.userUuid === _user?.uuid) {
          _connection.invoke("RemoveUserFromGroup", data?.orderUuid);
        }
      });
    } catch (err) {
      console.error("Error while establishing connection:", err);
    }

    setConnection(_connection);
  };

  return (
    <Context.Provider
      value={{
        signed,
        login,
        logout,
        setUnreadCount,
        getUnreadCount,
        user,
        assignedToModal,
        setAssignedToModal,
        newNoteModal,
        setNewNoteModal,
        unreadCount,
        connection,
        tabVisible,
        setTabVisible,
      }}
    >
      {children}
    </Context.Provider>
  );
};
