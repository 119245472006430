import React, { useEffect, useRef } from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Colors from "../../../constants/Colors";
import { Modalize } from "react-native-modalize";
import { useGetProductsForOrder } from "../../../hooks/orders/useGetProductsForOrder";
import Header from "../../../components/Header";
import { useNavigation } from "@react-navigation/native";
const { width } = Dimensions.get("window");

interface OrderProductsModalProps {
  open: boolean;
  onClose: () => void;
  orderUuid: string;
}

const OrderProductsModal: React.FC<OrderProductsModalProps> = ({
  open,
  onClose,
  orderUuid,
}) => {
  const { navigate, goBack } = useNavigation();
  const { data: orderProducts } = useGetProductsForOrder(orderUuid);
  const modalizeRef = useRef<Modalize>(null);

  useEffect(() => {
    if (open) {
      modalizeRef?.current?.open();
    }
  }, [open]);

  const goToOrder = (item: any) => {
    navigate("OrderDetails", {
      orderNumber: item.orderNumber,
      eventDate: item.eventDate,
      orderStatus: item.status,
      orderDate: item.orderDate,
      orderProductUuid: item.orderProductUuid,
    });
    modalizeRef?.current?.close();
  };

  return (
    <Modalize
      ref={modalizeRef}
      modalHeight={600}
      onClose={() => onClose()}
      modalStyle={styles.modal}
    >
      <Header title="Order Products" isModal />
      {orderProducts?.map((item, index) => (
        <TouchableOpacity
          key={item?.orderProductUuid}
          onPress={() => goToOrder(item)}
        >
          <View style={[styles.container, {
            borderTopWidth: index === 0 ? 1 : 0,
          }]
          }>
            <View style={styles.row}>
              <Text style={styles.title}>Product: {item.productTitle}</Text>
              <Text style={styles.variant}>Quantity: {item.quantity}</Text>
            </View>
            <Text style={styles.variant}>{item.variantOption1}</Text>
            <Text style={styles.variant}>{item.variantOption2}</Text>
            <Text style={styles.variant}>{item.variantOption3}</Text>
          </View>
        </TouchableOpacity>
      ))}
    </Modalize>
  );
};

export default OrderProductsModal;

const styles = StyleSheet.create({
  modal: {
    backgroundColor: Colors.light.background,
    paddingTop: 10,
  },
  title: {
    fontSize: 16,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
  },
  variant: {
    fontSize: 14,
    fontFamily: "spectral-400",
    color: Colors.light.brown,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    width: width,
    backgroundColor: "white",
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: Colors.light.messageOther,
    borderTopColor: Colors.light.messageOther,
    borderTopWidth: 1,
  },
});
