import { useContext, useEffect, useState } from "react";
import { RefreshControl, ScrollView, StyleSheet } from "react-native";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { Text, View } from "../../components/Themed";
import Colors from "../../constants/Colors";
import { RootTabScreenProps } from "../../types";
import React from "react";
import Context from "../../context";
import { useGetOrderCountsForUser } from "../../hooks/orders/useGetOrderCountsForUser";
import { useSearchOrders } from "../../hooks/orders/useSearchOrders";

export default function Tasks({ navigation }: RootTabScreenProps<"TabOne">) {
  const { user } = useContext(Context);
  const [isManager, setIsManager] = useState(false);
  const {
    data: counts,
    isLoading,
    refetch,
    isFetched,
  } = useGetOrderCountsForUser();

  //temp fix
  const [searchOrdersInput, setSearchOrdersInput] = useState<SearchOrdersInput>(
    {
      pageNumber: 1,
      pageSize: 20,
      searchTerm: " ",
      orderProductStatusFilter: ["FrameCompleted", "NoResponse"],
      orderByOrderDate: 0,
      assignedToMe: true,
      assignedToMyTeam: false,
    }
  );

  const {
    data,
    fetchNextPage,
    refetch: refetch2,
    isFetching,
  } = useSearchOrders(searchOrdersInput);

  const flatData = () => {
    const flatArray: any = [];
    data?.pages.map((page) => {
      flatArray.push.apply(flatArray, page.items);
    });
    return flatArray;
  };

  useEffect(() => {
    if (user?.role === "manager" || user?.role === "admin") {
      setIsManager(true);
    }
  }, [user]);

  return (
    <View style={styles.container}>
      <Header profile />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={!isFetched ? false : isLoading}
            onRefresh={() => {
              refetch();
            }}
          />
        }
        style={styles.subContainer}
        contentContainerStyle={styles.containerStyle}
      >
        <Text style={styles.titlePage}>Tasks</Text>

        <View style={styles.buttonContainer}>
          <Button
            label={`${flatData()?.length} - No Response`}
            onPress={() => {
              navigation.navigate("OrdersByTask", {
                title: "No Response",
                task: ["FrameCompleted", "NoResponse"],
                color: Colors.light.gold,
                count: flatData()?.length,
              });
            }}
          />
          <Button
            label={`${counts?.frameApprovedCount} - Approved`}
            onPress={() => {
              navigation.navigate("OrdersByTask", {
                task: ["Approved"],
                title: "Approved",
                color: Colors.light.limeGreen,
                count: counts?.frameApprovedCount,
              });
            }}
          />
          <Button
            label={`${counts?.frameDisapprovedCount} - Disapproved`}
            onPress={() => {
              navigation.navigate("OrdersByTask", {
                task: ["Disapproved"],
                title: "Disapproved",
                color: Colors.light.navy,
                count: counts?.frameDisapprovedCount,
              });
            }}
          />
          <Button
            label={`${counts?.readyToSealCount} - Ready to Seal`}
            onPress={() => {
              navigation.navigate("OrdersByTask", {
                task: ["ReadyToSeal"],
                title: "Ready to Seal",
                color: Colors.light.navy,
                count: counts?.readyToSealCount,
              });
            }}
          />
          {isManager ? (
            <>
              <Button
                backgroundColor={Colors.light.navy}
                label={`Team Member Tasks`}
                onPress={() => {
                  navigation.navigate("ManagerTaskView");
                }}
              />
              <Button
                backgroundColor={Colors.light.navy}
                label={`Tasks from your manager`}
                onPress={() => {
                  navigation.navigate("ManagerTasks");
                }}
              />
            </>
          ) : (
            <Button
              backgroundColor={Colors.light.navy}
              label={`Tasks from your manager`}
              onPress={() => {
                navigation.navigate("ManagerTasks");
              }}
            />
          )}
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  titlePage: {
    fontSize: 24,
    alignSelf: "center",
    fontFamily: "spectral-500",
    color: Colors.light.brown,
    marginBottom: 20,
  },
  containerStyle: { justifyContent: "center", flex: 1 },
  subContainer: { flex: 1, width: "100%" },
  buttonContainer: {
    marginBottom: 60,
    alignItems: "center",
    height: 340,
    justifyContent: "space-between",
    width: "100%",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
