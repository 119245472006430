import React from "react";
import Context from "../context";
import Colors from "../constants/Colors";

export function useGetStatusLabel(status: string) {
  const { user } = React.useContext(Context);
  let label = "";
  switch (status) {
    case "orderReceived":
      label = "Order Received";
      break;
    case "bouquetReceived":
      label = user?.role === "client" ? "Blooms received" : "Bouquet Received";
      break;
    case "checkedOn":
      label = user?.role === "client" ? "Quality Check" : "Checked On";
      break;
    case "progress":
      label = user?.role === "client" ? "Drying" : "Progress";
      break;
    case "almostReadyToFrame":
      label =
        user?.role === "client"
          ? "Preparing to Design"
          : "Almost Ready To Frame";
      break;
    case "readyToFrame":
      label = user?.role === "client" ? "Ready to Design" : "Ready To Frame";
      break;
    case "frameCompleted":
      label = user?.role === "client" ? "Design Completed" : "No Response";
      break;
    case "preparingToBeShipped":
      label =
        user?.role === "client"
          ? "Preparing for Shipment "
          : "Preparing To Be Shipped";
      break;
    case "shipped":
      label = "Shipped";
      break;
    case "noResponse":
      label = user?.role === "client" ? "Frame Completed" : "No Response";
      break;
    case "approved":
      label = user?.role === "client" ? "Preparing To be Shipped" : "Approved";
      break;
    case "disapproved":
      label = user?.role === "client" ? "Frame Completed" : "Disapproved";
      break;
    case "readyToSeal":
      label =
        user?.role === "client" ? "Preparing To Be Shipped" : "Ready To Seal";
      break;
    case "viewComment":
      label = "View Comment";
      break;
    case "cancelled":
      label = "Cancelled";
      break;
    default:
      label = status;
      break;
  }

  return label;
}

export function useGetStatusColor(status: string) {
  const { user } = React.useContext(Context);

  let color = "";
  switch (status) {
    case "orderReceived":
      color = Colors.light.navy;
      break;
    case "bouquetReceived":
      color = Colors.light.green;
      break;
    case "checkedOn":
      color = Colors.light.gold;
      break;
    case "progress":
      color = Colors.light.pink;
      break;
    case "almostReadyToFrame":
      color = Colors.light.orange;
      break;
    case "readyToFrame":
      color = Colors.light.purple;
      break;
    case "frameCompleted":
      color = user?.role === "client" ? Colors.light.red : Colors.light.gold;
      break;
    case "preparingToBeShipped":
      color = Colors.light.blue;
      break;
    case "shipped":
      color = Colors.light.lightGreen;
      break;
    case "noResponse":
      color = user?.role === "client" ? Colors.light.red : Colors.light.gold;
      break;
    case "approved":
      color =
        user?.role === "client" ? Colors.light.blue : Colors.light.limeGreen;
      break;
    case "disapproved":
      color = user?.role === "client" ? Colors.light.red : Colors.light.danger;
      break;
    case "readyToSeal":
      color =
        user?.role === "client" ? Colors.light.blue : Colors.light.pastelPink;
      break;
    case "bouquet":
      color = Colors.light.navy;
      break;
    case "frame":
      color = Colors.light.pink;
      break;
    case "florist":
      color = Colors.light.limeGreen;
      break;
    case "viewComment":
      color = Colors.light.brown;
      break;
    case "cancelled":
      color = Colors.light.black;
      break;
    default:
      color = Colors.light.navy;
      break;
  }

  return color;
}
