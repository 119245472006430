/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { Feather, FontAwesome } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, { useContext, useEffect } from "react";
import { ColorSchemeName, Platform, StyleSheet } from "react-native";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import ModalScreen from "../screens/ModalScreen";
import { RootStackParamList, RootTabParamList } from "../types";
import LinkingConfiguration from "./LinkingConfiguration";
import Context from "../context";
import Login from "../screens/Login";
import ForgotPassword from "../screens/Login/ForgotPassword";
import ResetPassword from "../screens/Login/ResetPassword";
import MyFrames from "../screens/MyFrames";
import Orders from "../screens/Orders";
import Tasks from "../screens/Tasks";
import Email from "../screens/Email";
import Calendar from "../screens/Calendar";
import Profile from "../screens/Profile";
import StageTotals from "../screens/Profile/StageTotals";
import StageDetails from "../screens/Profile/StageDetails";
import UserList from "../screens/Profile/UserList";
import AddUser from "../screens/Profile/AddUser";
import OrderDetails from "../screens/OrderDetails";
import CreateNote from "../screens/CreateNote";
import OrdersByTask from "../screens/OrdersByTask";
import BulkEmail from "../screens/BulkEmail";
import ManagerTasks from "../screens/ManagerTasks";
import TaskDetails from "../screens/TaskDetails";
import ManagerTaskView from "../screens/ManagerTasks/ManagerTaskView";
import ManagerTasksByUser from "../screens/ManagerTasks/ManagerTasksByUser";
import ManagerCreateTask from "../screens/ManagerTasks/ManagerCreateTask";
import Client from "../screens/Client";
import ClientOrderProducts from "../screens/ClientOrderProducts";
import ClientProductDetails from "../screens/ClientProductDetails";
import Splashscreen from "../screens/Splashscreen";
import { navigationRef } from "./RootNavigation";
import ImagePreview from "../screens/ImagePreview";
import ClientFrameApproval from "../screens/ClientFrameApproval";
import FilterFramesScreen from "../screens/FilterFramesScreen";
import ReadEmail from "../screens/ReadEmail";
import UploadImageAndComment from "../screens/UploadImageAndComment";
import ReadEmailMessage from "../screens/ReadEmailMessage";
import {
  STORAGE_KEYS,
  getLocalData,
  storeLocalData,
} from "../services/storage";
import CheckRegistrationStatus from "../screens/Login/CheckRegistrationStatus";
import { View } from "../components/Themed";
import Conversations from "../screens/Conversations";
import ConversationsByOrder from "../screens/ConversationsByOrder";
import Conversation from "../screens/Conversation";
import ConversationDetails from "../screens/ConversationDetails";
import ConversationAddUser from "../screens/ConversationAddUser";
import UpdateAvatar from "../screens/UpdateAvatar";
import { TransitionPresets } from "@react-navigation/stack";
import BottomBar from "./BottomBar";
import OrderScreen from "../screens/OrderScreen";
import OrdersNew from "../screens/OrdersNew";
import AddNote from "../screens/AddNote";
import EditUser from "../screens/EditUser";
import UpdateEmail from "../screens/UpdateEmail";

export default function Navigation({
  linking,
  colorScheme,
}: {
  linking: any;
  colorScheme: ColorSchemeName;
}) {
  const [isReady, setIsReady] = React.useState(false);
  const [initialState, setInitialState] = React.useState();

  React.useEffect(() => {
    const restoreState = async () => {
      try {
        if (Platform.OS == "web") {
          const savedStateString = await getLocalData(STORAGE_KEYS.HISTORY);
          const state = savedStateString
            ? JSON.parse(savedStateString)
            : undefined;

          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  const stateChange = async (state: any) => {
    await storeLocalData(STORAGE_KEYS.HISTORY, JSON.stringify(state));
  };

  return (
    <NavigationContainer
      documentTitle={{
        formatter: () => `Pressed Floral`,
      }}
      ref={navigationRef}
      linking={linking}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      initialState={Platform.OS === "web" ? initialState : undefined}
      onStateChange={(state) => Platform.OS === "web" && stateChange(state)}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}
/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const { signed, user } = useContext(Context);

  return (
    <Stack.Navigator>
      {signed === null ? (
        <Stack.Screen
          name="Splash"
          component={Splashscreen}
          options={{ headerShown: false }}
        />
      ) : (
        <>
          {signed === true ? (
            <>
              {user?.role !== "client" ? (
                <Stack.Screen
                  name="Root"
                  component={BottomTabNavigator}
                  options={{ headerShown: false }}
                />
              ) : (
                <Stack.Screen
                  name="ClientBottomTabNavigator"
                  component={ClientBottomTabNavigator}
                  options={{ headerShown: false }}
                />
              )}
            </>
          ) : (
            <Stack.Screen
              name="LoginNavigator"
              component={LoginNavigator}
              options={{ headerShown: false }}
            />
          )}
        </>
      )}

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="Modal"
          component={ModalScreen}
          options={{ headerShown: false }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}

function ClientNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Client"
        component={Client}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ClientOrderProducts"
        component={ClientOrderProducts}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ClientProductDetails"
        component={ClientProductDetails}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ClientFrameApproval"
        component={ClientFrameApproval}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="ImagePreview"
        component={ImagePreview}
        options={{
          headerShown: false,
          ...TransitionPresets.BottomSheetAndroid,
        }}
      />
      <Stack.Screen
        name="Logout"
        component={Logout}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
}

function ClientChatNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Conversations"
        component={Conversations}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Conversation"
        component={Conversation}
        options={{
          headerShown: false,
          ...TransitionPresets.BottomSheetAndroid,
        }}
      />
      <Stack.Screen
        name="ImagePreview"
        component={ImagePreview}
        options={{
          headerShown: false,
          ...TransitionPresets.BottomSheetAndroid,
        }}
      />
    </Stack.Navigator>
  );
}

const ClientBottomTab = createBottomTabNavigator<RootTabParamList>();

function ClientBottomTabNavigator() {
  const { unreadCount } = useContext(Context);
  return (
    <ClientBottomTab.Navigator
      initialRouteName="ClientNavigator"
      tabBar={(props) => <BottomBar isClient />}
      screenOptions={{
        tabBarActiveTintColor: Colors.light.navy,
        tabBarShowLabel: false,
        tabBarStyle: {
          backgroundColor: Colors.light.background,
          borderTopColor: "transparent",
        },
      }}
    >
      <ClientBottomTab.Screen
        name="ClientNavigator"
        component={ClientNavigator}
        options={{
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="package" color={color} />
          ),
        }}
      />
      <ClientBottomTab.Screen
        name="ClientChatNavigator"
        component={ClientChatNavigator}
        options={{
          tabBarIcon: ({ color }) => (
            <TabBarIcon
              unread={unreadCount > 0}
              name="message-square"
              color={color}
            />
          ),
          headerShown: false,
        }}
      />
    </ClientBottomTab.Navigator>
  );
}

function LoginNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Login"
        component={Login}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ForgotPassword"
        component={ForgotPassword}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ResetPassword"
        component={ResetPassword}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="CheckRegistrationStatus"
        component={CheckRegistrationStatus}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
}

function OrdersNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Orders"
        component={Orders}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="OrderScreen"
        component={OrderScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="AddNote"
        component={AddNote}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="OrderDetails"
        component={OrderDetails}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpdateEmail"
        component={UpdateEmail}
        options={{
          presentation: "modal",
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="CreateNoteImage"
        component={UploadImageAndComment}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="ImagePreview"
        component={ImagePreview}
        options={{
          headerShown: false,
          ...TransitionPresets.BottomSheetAndroid,
        }}
      />
      <Stack.Screen
        name="CreateNote"
        component={CreateNote}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="BulkEmail"
        component={BulkEmail}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="Conversation"
        component={Conversation}
        options={{
          ...TransitionPresets.BottomSheetAndroid,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ConversationDetails"
        component={ConversationDetails}
        options={{
          presentation: "modal",
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ConversationAddUser"
        component={ConversationAddUser}
        options={{
          presentation: "modal",
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

const Logout = () => {
  const { logout } = useContext(Context);
  useEffect(() => {
    logout();
  }, []);
  return null;
};

function BottomTabNavigator() {
  const colorScheme = useColorScheme();
  const { unreadCount } = useContext(Context);

  return (
    <BottomTab.Navigator
      initialRouteName="OrdersNavigator"
      tabBar={(props) => <BottomBar />}
      screenOptions={{
        tabBarActiveTintColor: Colors.light.navy,
        tabBarShowLabel: false,
        tabBarStyle: {
          backgroundColor: Colors.light.background,
          borderTopColor: "transparent",
        },
      }}
    >
      <BottomTab.Screen
        name="Logout"
        component={Logout}
        options={{
          headerShown: false,
          tabBarButton: () => null,
        }}
      />
      <BottomTab.Screen
        name="ProfileNavigator"
        component={ProfileNavigator}
        options={{
          tabBarButton: () => null,
          headerShown: false,
        }}
      />
      <BottomTab.Screen
        name="OrdersNavigator"
        component={OrdersNavigator}
        options={{
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="package" color={color} />
          ),
        }}
      />
      <BottomTab.Screen
        name="TaskNavigator"
        component={TaskNavigator}
        options={{
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="check-square" color={color} />
          ),
        }}
      />
      <BottomTab.Screen
        name="MyFrames"
        component={MyFrameNavigator}
        options={{
          headerShown: false,
          tabBarIcon: ({ color }) => <TabBarIcon name="heart" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="ChatNav"
        component={ChatNavigator}
        options={{
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <TabBarIcon
              unread={unreadCount > 0}
              name="message-square"
              color={color}
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="EmailNavigator"
        component={EmailNavigator}
        options={{
          headerShown: false,
          tabBarIcon: ({ color }) => <TabBarIcon name="mail" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="CalendarNavigator"
        component={CalendarNavigator}
        options={{
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="calendar" color={color} />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
}

function MyFrameNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="MyFramesScreen"
        component={MyFrames}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="FilterFramesScreen"
        component={FilterFramesScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="OrderDetails"
        component={OrderDetails}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="OrderScreen"
        component={OrderScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="AddNote"
        component={AddNote}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="UpdateEmail"
        component={UpdateEmail}
        options={{
          presentation: "modal",
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="CreateNoteImage"
        component={UploadImageAndComment}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="ImagePreview"
        component={ImagePreview}
        options={{
          headerShown: false,
          ...TransitionPresets.BottomSheetAndroid,
        }}
      />
      <Stack.Screen
        name="Conversation"
        component={Conversation}
        options={{
          ...TransitionPresets.BottomSheetAndroid,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ConversationDetails"
        component={ConversationDetails}
        options={{
          presentation: "modal",
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ConversationAddUser"
        component={ConversationAddUser}
        options={{
          presentation: "modal",
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}

function TaskNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Tasks"
        component={Tasks}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="OrdersByTask"
        component={OrdersByTask}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="OrderDetails"
        component={OrderDetails}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="OrderScreen"
        component={OrderScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="AddNote"
        component={AddNote}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="UpdateEmail"
        component={UpdateEmail}
        options={{
          presentation: "modal",
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="CreateNoteImage"
        component={UploadImageAndComment}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="ImagePreview"
        component={ImagePreview}
        options={{
          headerShown: false,
          ...TransitionPresets.BottomSheetAndroid,
        }}
      />
      <Stack.Screen
        name="ManagerTasks"
        component={ManagerTasks}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ManagerTasksByUser"
        component={ManagerTasksByUser}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ManagerTaskView"
        component={ManagerTaskView}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ManagerCreateTask"
        component={ManagerCreateTask}
        options={{
          headerShown: false,
          presentation: "modal",
          gestureEnabled: false,
        }}
      />
      <Stack.Screen
        name="TaskDetails"
        component={TaskDetails}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="Conversation"
        component={Conversation}
        options={{
          ...TransitionPresets.BottomSheetAndroid,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ConversationDetails"
        component={ConversationDetails}
        options={{
          presentation: "modal",
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ConversationAddUser"
        component={ConversationAddUser}
        options={{
          presentation: "modal",
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}

const ChatNavigator = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Chat"
        component={Conversations}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Conversation"
        component={Conversation}
        options={{
          ...TransitionPresets.BottomSheetAndroid,
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ConversationDetails"
        component={ConversationDetails}
        options={{
          presentation: "modal",
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ConversationAddUser"
        component={ConversationAddUser}
        options={{
          presentation: "modal",
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ImagePreview"
        component={ImagePreview}
        options={{
          headerShown: false,
          ...TransitionPresets.BottomSheetAndroid,
        }}
      />
      <Stack.Screen
        name="OrderDetails"
        component={OrderDetails}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="OrderScreen"
        component={OrderScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="AddNote"
        component={AddNote}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="UpdateEmail"
        component={UpdateEmail}
        options={{
          presentation: "modal",
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="CreateNoteImage"
        component={UploadImageAndComment}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="CreateNote"
        component={CreateNote}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="BulkEmail"
        component={BulkEmail}
        options={{ headerShown: false, presentation: "modal" }}
      />
    </Stack.Navigator>
  );
};

const EmailNavigator = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Email"
        component={Email}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ReadEmail"
        component={ReadEmail}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="BulkEmail"
        component={BulkEmail}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ReadEmailMessage"
        component={ReadEmailMessage}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

const CalendarNavigator = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Calendar"
        component={Calendar}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="OrderDetails"
        component={OrderDetails}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="OrderScreen"
        component={OrderScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="AddNote"
        component={AddNote}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="UpdateEmail"
        component={UpdateEmail}
        options={{
          presentation: "modal",
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="CreateNoteImage"
        component={UploadImageAndComment}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="ImagePreview"
        component={ImagePreview}
        options={{
          headerShown: false,
          ...TransitionPresets.BottomSheetAndroid,
        }}
      />
      <Stack.Screen
        name="Conversation"
        component={Conversation}
        options={{
          ...TransitionPresets.FadeFromBottomAndroid,
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
};

const ProfileNavigator = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Profile"
        component={Profile}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UpdateAvatar"
        component={UpdateAvatar}
        options={{ headerShown: false, presentation: "modal" }}
      />
      <Stack.Screen
        name="StageTotals"
        component={StageTotals}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="StageDetails"
        component={StageDetails}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="UserList"
        component={UserList}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="EditUser"
        component={EditUser}
        options={{
          presentation: "modal",
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="AddUser"
        component={AddUser}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>["name"];
  color: string;
  unread?: boolean;
}) {
  return (
    <View>
      {props.unread ? (
        <View style={styles.dot}>
          <View style={styles.dotInner} />
        </View>
      ) : null}
      <Feather size={30} style={{ marginBottom: -3 }} {...props} />
    </View>
  );
}

const styles = StyleSheet.create({
  dot: {
    position: "absolute",
    top: -3,
    right: -5,
    height: 16,
    width: 16,
    borderRadius: 9,
    backgroundColor: Colors.light.background,
    zIndex: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  dotInner: {
    backgroundColor: Colors.light.messageBlue,
    height: 11,
    width: 11,
    borderRadius: 6,
  },
});
