import React from "react";
import { StyleSheet, KeyboardAvoidingView, View, Platform } from "react-native";
import Input from "../../components/Input";
import window from "../../constants/Layout";
import { useForm } from "react-hook-form";
import Button from "../../components/Button";
import Toast from "react-native-toast-message";
import { useNavigation } from "@react-navigation/native";
import Colors from "../../constants/Colors";
import ModalBody from "../../components/ModalBody";
import instanceAXIOS from "../../services/api";
import ModalHeader from "../../components/ModalHeader";
import Header from "../../components/Header";

export default function ForgotPassword() {
  const navigate = useNavigation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.email = data.email.toLowerCase();
    instanceAXIOS
      .post("/Authentication/SendPasswordResetCode", data)
      .then((res) => {
        Toast.show({
          type: "success",
          props: {
            message: "Email sent with code to reset password",
          },
        });
        setTimeout(() => {
          navigate.goBack();
          navigate.navigate("ResetPassword", { newUser: false });
        }, 500);
      })
      .catch((err) => {
        if (Platform.OS === "web") {
          alert("Please register with the code from your welcome email");
          navigate.navigate("ResetPassword", { newUser: true });
        } else {
          Toast.show({
            type: "error",
            props: {
              message: "Email not found",
            },
          });
        }
      });
  };

  return (
    <View style={styles.body}>
      <Header goBack title="Forgot Password" />
      <KeyboardAvoidingView style={styles.flex} behavior={"height"}>
        <View style={styles.container}>
          <Input
            control={control}
            errors={errors}
            name="email"
            required
            placeholder="Email"
            autoCapitalize="none"
            keyboardType="email-address"
          />
          <Button
            label={"Reset Password"}
            onPress={handleSubmit(onSubmit)}
            backgroundColor={Colors.light.lightGreen}
          />
        </View>
      </KeyboardAvoidingView>
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    backgroundColor: Colors.light.background,
    justifyContent: "center",
  },
  container: {
    marginTop: 80,
    marginHorizontal: 40,
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: Platform.OS !== "web" ? window.window.width : 400,
    alignSelf: "center",
    width: "90%",
  },
  flex: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
});
