import { useForm } from "react-hook-form";
import { StyleSheet, TouchableOpacity } from "react-native";
import Header from "../../components/Header";
import { Text, View } from "../../components/Themed";
import TileListItem from "../../components/TileListItem";
import Colors from "../../constants/Colors";
import { RootTabScreenProps } from "../../types";
import React, { useContext, useEffect, useState } from "react";
import {
  SearchOrdersInput,
  useSearchOrders,
} from "../../hooks/orders/useSearchOrders";
import FlashList from "../../components/FlashList";
import LoadingState from "../../components/LoadingState";
import Context from "../../context";
import Controls from "../Orders/Controls";
import { formatDate } from "../../services/utils";
import { Feather } from "@expo/vector-icons";

const statusData = [
  { label: "All", value: null },
  { label: "No Response", value: "FrameCompleted" },
  { label: "Approved", value: "Approved" },
  { label: "Disapproved", value: "Disapproved" },
  { label: "Ready To Seal", value: "ReadyToSeal" },
];

export default function MyFrames({ navigation }: RootTabScreenProps<"TabOne">) {
  const { user } = useContext(Context);
  const [searchOrdersInput, setSearchOrdersInput] = useState<SearchOrdersInput>(
    {
      pageNumber: 1,
      pageSize: 20,
      searchTerm: "",
      orderProductStatusFilter: null,
      orderByOrderDate: 0,
      assignedToMe: true,
      assignedToMyTeam: true,
      orderByEventDate: false,
    }
  );

  const [statusDropDownIsOpen, setStatusDropDownIsOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const [sort, setSort] = useState("asc");
  const [statuses, setStatuses] = useState(statusData);

  const { data, isLoading, fetchNextPage } = useSearchOrders(searchOrdersInput);

  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const flatData = () => {
    const flatArray: any = [];
    data?.pages.map((page) => {
      flatArray.push.apply(flatArray, page.items);
    });
    return flatArray;
  };

  const search = watch("Search");
  const statusFilter = watch("Status");

  useEffect(() => {
    let _filter = null;
    if (statusFilter) {
      _filter = [statusFilter];
      if (_filter[0] === "FrameCompleted") {
        _filter = ["FrameCompleted", "NoResponse"];
      }
    }
    setSearchOrdersInput({
      ...searchOrdersInput,
      searchTerm: search,
      orderProductStatusFilter: _filter,
    });
  }, [search, statusFilter]);

  useEffect(() => {
    if (user?.role?.toLowerCase() === "manager") {
      setSearchOrdersInput({
        ...searchOrdersInput,
        assignedToMyTeam: true,
      });
    } else if (user?.role?.toLowerCase() === "admin") {
      setSearchOrdersInput({
        ...searchOrdersInput,
        assignedToMyTeam: false,
        assignedToMe: false,
      });
    }
  }, [user]);

  useEffect(() => {
    setValue("Status", status);
  }, [status]);

  return (
    <View style={styles.container}>
      <Header profile />
      <View style={styles.pageContainer}>
        <View
          style={{
            paddingHorizontal: 20,
            alignItems: "flex-end",
          }}
        >
          <TouchableOpacity
            onPress={() => navigation.navigate("FilterFramesScreen")}
            hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
          >
            <Feather name="filter" size={24} color={Colors.light.brown} />
          </TouchableOpacity>
        </View>
        <Text style={styles.titlePage}>My Frames</Text>
        <Controls
          control={control}
          errors={errors}
          sort={sort}
          setSort={(_stort) => {
            setSort(_stort);
            setSearchOrdersInput({
              ...searchOrdersInput,
              orderByOrderDate: sort === "asc" ? 1 : 0,
            });
          }}
          statusDropDownIsOpen={statusDropDownIsOpen}
          setStatusDropDownIsOpen={setStatusDropDownIsOpen}
          statuses={statuses}
          status={status}
          setStatus={setStatus}
          setStatuses={setStatuses}
          totalItems={data?.pages[0]?.totalItems}
        />
        <FlashList
          data={flatData()}
          renderItem={({ item, index }) => (
            <TileListItem
              orderNumber={item.shopifyOrderNumber}
              orderStatus={item.status}
              orderDate={formatDate(item.orderDate)}
              assignedToName={`${item.assignedToUserFirstName} ${item.assignedToUserLastName}`}
              orderName={`${item.clientFirstName} ${item.clientLastName}`}
              eventDate={formatDate(item.eventDate)}
              orderNotes={index}
              orderProductUuid={item.uuid}
              image={item?.frameValetKey?.blobUri}
              imageUuid={item?.frameValetKey?.uuid}
            />
          )}
          numColumns={2}
          estimatedItemSize={60}
          ListEmptyComponent={
            <LoadingState
              empty
              emptyIcon="package"
              emptyText="No Orders Found"
            />
          }
          contentContainerStyle={styles.flatlist}
          loadingComponent={<LoadingState />}
          isLoading={isLoading}
          onEndReachedThreshold={0.5}
          onEndReached={() => {
            fetchNextPage();
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  controlsContainer: { paddingHorizontal: 16, marginBottom: 12 },
  pageContainer: { flex: 1, width: "100%" },
  dropDownContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  resultsContainer: {
    width: "30%",
  },
  titlePage: {
    fontSize: 24,
    alignSelf: "center",
    fontFamily: "spectral-500",
    color: Colors.light.brown,
  },
  flatlistContent: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  resultsText: {
    marginTop: 4,
    fontSize: 20,
    fontFamily: "spectral-600",
    color: Colors.light.brown,
  },
  flatlist: {
    paddingBottom: 200,
  },
  label: {
    color: Colors.light.brown,
    marginBottom: 4,
    fontSize: 14,
    fontFamily: "spectral-600",
  },
  container: {
    flex: 1,
    alignItems: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
