import React from "react";
import { StyleSheet, FlatList, ScrollView } from "react-native";
import Header from "../../components/Header";
import { Text, View } from "../../components/Themed";
import { RootTabScreenProps } from "../../types";
import Button from "../../components/Button";
import Context from "../../context";
import Colors from "../../constants/Colors";
import { useGetDashboardStates } from "../../hooks/users/useGetDashboardStats";
import { formatDate } from "../../services/utils";
import App from "../../app.json";

export default function Profile({ navigation }: RootTabScreenProps<"TabOne">) {
  const { logout, user } = React.useContext(Context);
  const [isManager, setIsManager] = React.useState(true);
  const [data, setData] = React.useState([
    {
      title: "Estimated bouquets arriving this week:",
      value: null,
    },
    {
      title: "Goal date for designs:",
      value: null,
    },
    {
      title: "Current date of designs:",
      value: null,
    },
    {
      title: "Orders ready to ship:",
      value: null,
    },
  ]);
  const { data: stats } = useGetDashboardStates();
  React.useEffect(() => {
    if (stats) {
      setData([
        {
          title: "Estimated bouquets arriving this week:",
          value: stats?.estimatedBouquetsArriving,
        },
        {
          title: "Goal date for designs:",
          value: formatDate(stats?.goalDateForDesign),
        },
        {
          title: "Current date of designs:",
          value: formatDate(stats?.currentDateOfDesigns),
        },
        {
          title: "Orders ready to ship:",
          value: stats?.ordersReadyToShip,
        },
      ]);
    }
  }, [stats]);

  React.useEffect(() => {
    if (user?.role === "admin" || user?.role === "manager") {
      setIsManager(true);
    } else {
      setIsManager(false);
    }
  }, [user]);

  const _capitalize = (str: string) => {
    if (str && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

  return (
    <View style={styles.container} testID="ID_PROFILE_VIEW">
      <Header profilePage />
      <ScrollView
        style={styles.innerContainer}
        contentContainerStyle={styles.contentStyle}
      >
        <Text style={styles.title}>{`${_capitalize(
          user?.firstName
        )} ${_capitalize(user?.lastName)}`}</Text>
        <Text style={styles.subTitle}>{`${_capitalize(user?.role)}`}</Text>
        <View style={isManager ? styles.managerInner : styles.inner}>
          <FlatList
            data={data}
            renderItem={({ item }) => (
              <View style={styles.itemBody}>
                <Text style={styles.goalText}>{item.title}</Text>
                <Text style={styles.goalValueText}>{item.value}</Text>
              </View>
            )}
            scrollEnabled={false}
            keyExtractor={(item) => item.title}
            contentContainerStyle={styles.flatListContainer}
          />
        </View>
        {isManager ? (
          <View style={styles.buttonContainer}>
            <Button
              label={"Stage Totals"}
              onPress={() => navigation.navigate("StageTotals")}
            />
            <Button
              label={"Users"}
              onPress={() => navigation.navigate("UserList")}
            />
            <Button
              label={"Update Avatar"}
              onPress={() => navigation.navigate("UpdateAvatar")}
            />
            <Button label={"Logout"} onPress={logout} />
          </View>
        ) : (
          <View style={styles.buttonContainer}>
            <Button
              label={"Update Avatar"}
              onPress={() => navigation.navigate("UpdateAvatar")}
            />
            <Button label={"Logout"} onPress={logout} />
          </View>
        )}
        <Text style={{ marginTop: -25, color: Colors.light.brown }}>
          {`${App.expo.version} v${App.expo.ios.buildNumber}`}
        </Text>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  innerContainer: {
    flex: 1,
    width: "96%",
  },
  contentStyle: {
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingBottom: 60,
    flex: 1,
  },
  buttonContainer: {
    marginTop: 0,
    width: "100%",
    alignItems: "center",
  },
  title: {
    fontSize: 16,
    textAlign: "center",
    fontWeight: "600",
    fontFamily: "spectral-600",
  },
  subTitle: {
    fontSize: 14,
    textAlign: "center",
    fontWeight: "600",
    fontFamily: "spectral-600",
    color: Colors.light.brown,
    marginTop: -30,
    marginBottom: -10,
  },
  inner: {
    backgroundColor: Colors.light.messageOther,
    height: "55%",
    minHeight: 200,
    maxHeight: 370,
    justifyContent: "space-evenly",
    padding: 10,
    margin: 8,
    borderRadius: 6,
  },
  managerInner: {
    backgroundColor: Colors.light.messageOther,
    height: "50%",
    width: "100%",
    justifyContent: "space-evenly",
    padding: 10,
    borderRadius: 6,
  },
  flatListContainer: {
    flex: 1,
    justifyContent: "space-between",
    padding: 10,
  },
  goalText: {
    fontSize: 18,
    fontFamily: "spectral-600",
  },
  goalValueText: {
    fontSize: 22,
    fontFamily: "spectral-500",
    marginTop: 3,
    color: Colors.light.brown,
  },
  itemBody: { backgroundColor: "transparent" },
});
