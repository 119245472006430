import { Feather } from "@expo/vector-icons";
import React, { useContext } from "react";
import {
  Dimensions,
  Linking,
  RefreshControl,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
} from "react-native";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { View } from "../../components/Themed";
import UploadFrame from "../../components/UploadFrame";
import Colors from "../../constants/Colors";
import { RootTabScreenProps } from "../../types";
import { useGetOrderDetails } from "../../hooks/orders/useGetOrderDetails";
import UpdateOrderOwner from "./UpdateOrderOwner";
import Context from "../../context";
import UpdateOrderStatus from "./UpdateOrderStatus";
import { useNavigation } from "@react-navigation/native";
import { Toast } from "react-native-toast-message/lib/src/Toast";
import NewNote from "./NewNote";
import Note from "./ViewNotes/Note";
import OrderProductQuickDetails from "../OrderScreen/OrderProductQuickDetails";
import StatusPill from "../../components/StatusPill";
import { capitalizeFirstLetter } from "../../services/utils";

  const { width } = Dimensions.get("window");


export default function OrderDetails({ route }: RootTabScreenProps<"TabOne">) {
  const { orderNumber, orderStatus, orderProductUuid } = route.params;
  const navigation = useNavigation();
  const { assignedToModal, newNoteModal, user } = useContext(Context);
  const updateStatusModal = React.useRef(null);
  const { data, refetch, isFetched, isLoading } =
    useGetOrderDetails(orderProductUuid);

  const _sendEmail = () => {
    const clientEmail = encodeURIComponent(data?.clientEmail);
    const subject = encodeURIComponent(
      `Pressed Floral - Order: #${data?.shopifyOrderNumber}`
    );
    Linking.openURL(`mailto:${clientEmail}?subject=${subject}`);
  };

  return (
    <View style={styles.container}>
      <Header goBack />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={!isFetched ? false : isLoading}
            onRefresh={() => {
              refetch();
            }}
          />
        }
        style={styles.pageContainer}
        contentContainerStyle={styles.contentContainer}
      >
        <View style={styles.row}>
          <Text style={styles.metaDataTitle}>Order Number: </Text>
          <Text style={styles.metaData}>{`#${
            data?.shopifyOrderNumber || orderNumber
          }`}</Text>
        </View>
        <TouchableOpacity
          disabled={user?.role !== "manager" && user?.role !== "admin"}
          onPress={() => assignedToModal.current?.open()}
          style={styles.row}
        >
          <Text style={styles.metaDataTitle}>Assigned To: </Text>
          <Text style={styles.metaData}>{`${
            data?.assignedToUserFirstName || ""
          } ${data?.assignedToUserLastName || ""}`}</Text>
          {user?.role === "manager" || user?.role === "admin" ? (
            <Feather
              name="edit-2"
              size={14}
              color={Colors.light.brown}
              style={styles.icon}
            />
          ) : null}
        </TouchableOpacity>
        <View style={styles.row}>
          <Text style={styles.metaDataTitle}>Location: </Text>
          <Text style={styles.metaData}>
            {capitalizeFirstLetter(data?.location)}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.metaDataTitle}>Status: </Text>
          <TouchableOpacity
            disabled={data?.status === "cancelled"}
            onPress={() => updateStatusModal?.current?.open()}
          >
            <StatusPill status={data?.status || orderStatus} />
          </TouchableOpacity>
        </View>
        <View style={styles.space} />
        <View style={styles.detailsContainer}>
          <OrderProductQuickDetails
            product={data?.productTitle}
            varient={data?.variantOption1}
            varient2={data?.variantOption2}
            quantity={data?.quantity}
            status={data?.status}
          />
        </View>
        <View style={styles.space} />
        <TouchableOpacity
          onPress={() => {
            newNoteModal.current?.open();
          }}
          style={styles.row}
        >
          <Text style={styles.metaDataTitle}>Internal Notes: </Text>
          <Feather
            name="edit-2"
            size={14}
            color={Colors.light.brown}
            style={styles.icon}
          />
        </TouchableOpacity>
        {data?.notes?.map((note) => (
          <View key={note?.uuid} style={{ width: "80%" }}>
            <Note note={{ ...note, isPrivate: true }} />
          </View>
        ))}
        {data?.status?.toLowerCase() === "disapproved" && (
          <View style={styles.rowNotes}>
            <Text
              style={[
                styles.metaDataTitle,
                {
                  marginTop: 20,
                },
              ]}
            >
              Disapprove Comment:
            </Text>
            <Text style={styles.metaDataNotes}>{data?.comment}</Text>
          </View>
        )}
        <View style={styles.space} />
        <UploadFrame
          onRefresh={() => {
            refetch();
            Toast.show({
              type: "success",
              props: {
                message: "Refreshing",
              },
            });
          }}
          name="Upload Bouquet"
          uploads={data?.orderProductUploads.filter(
            (upload) => upload.uploadType === "bouquet"
          )}
          orderProductUuid={orderProductUuid}
          imageUploadType="Bouquet"
        />
        <UploadFrame
          onRefresh={() => {
            refetch();
            Toast.show({
              type: "success",
              props: {
                message: "Refreshing",
              },
            });
          }}
          name="Reordered Blooms"
          uploads={data?.orderProductUploads.filter(
            (upload) => upload.uploadType === "florist"
          )}
          orderProductUuid={orderProductUuid}
          imageUploadType="Florist"
          disabled={
            data?.orderProductUploads.filter(
              (upload) => upload.uploadType === "bouquet"
            )?.length === 0
          }
          disabledMessage="Please upload a bouquet first"
        />
        <UploadFrame
          onRefresh={() => {
            refetch();
            Toast.show({
              type: "success",
              props: {
                message: "Refreshing",
              },
            });
          }}
          name="Upload Frame"
          uploads={data?.orderProductUploads.filter(
            (upload) => upload.uploadType === "frame"
          )}
          orderProductUuid={orderProductUuid}
          imageUploadType="Frame"
          disabled={
            data?.orderProductUploads.filter(
              (upload) => upload.uploadType === "bouquet"
            )?.length === 0
          }
          disabledMessage="Please upload a bouquet first"
        />
        <UploadFrame
          onRefresh={() => {
            refetch();
            Toast.show({
              type: "success",
              props: {
                message: "Refreshing",
              },
            });
          }}
          name="Client Event Photos"
          uploads={data?.orderProductUploads.filter(
            (upload) => upload.uploadType === "client"
          )}
          orderProductUuid={orderProductUuid}
          imageUploadType="Client"
          hideUpload
          hideComments
        />
        <View style={styles.buttonPadding}>
          <Button
            backgroundColor={Colors.light.navy}
            label={"Send Email From Template"}
            onPress={() => {
              navigation.navigate("BulkEmail", {
                person: {
                  firstName: data?.clientFirstName,
                  lastName: data?.clientLastName,
                },
                orderProduct: { uuid: orderProductUuid },
              });
            }}
          />
        </View>
        <View style={styles.buttonPadding}>
          <Button
            backgroundColor={Colors.light.navy}
            label={"Send Email"}
            onPress={() => {
              _sendEmail();
            }}
          />
        </View>
      </ScrollView>
      <UpdateOrderOwner
        orderUuid={orderProductUuid}
        currentOwner={data?.assignedToUserUuid}
      />
      <UpdateOrderStatus
        modalRef={updateStatusModal}
        currentStatus={orderStatus}
        orderProductUuid={orderProductUuid}
      />
      <NewNote orderUuid={orderProductUuid} />
    </View>
  );
}

const styles = StyleSheet.create({
  buttonPadding: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  buttonPaddingBottom: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 40,
  },
  row: {
    flexDirection: "row",
    width: "80%",
    marginBottom: 4,
    alignItems: "center",
  },
  icon: {
    marginLeft: 6,
  },
  rowNotes: {
    width: "80%",
    marginBottom: 4,
  },
  rowNotesTitle: {
    width: "80%",
    marginBottom: 4,
    marginTop: 20,
  },
  pageContainer: { flex: 1, width: width },
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 100,
  },
  container: {
    flex: 1,
    alignItems: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  metaData: {
    fontFamily: "spectral-600",
    fontSize: 16,
    marginLeft: 4,
    color: Colors.light.brown,
  },
  metaDataNotes: {
    fontFamily: "spectral-600",
    fontSize: 16,
    marginLeft: 4,
    paddingRight: 20,
    color: Colors.light.brown,
  },
  metaDataTitle: {
    fontFamily: "spectral-600",
    fontSize: 16,
  },
  hit: {
    top: 20,
    bottom: 20,
    left: 20,
    right: 20,
  },
  addressContainer: {
    width: "80%",
    marginBottom: 4,
    marginLeft: 20,
  },
  addressText: {
    fontFamily: "spectral-600",
    fontSize: 14,
    marginLeft: 4,
    color: Colors.light.brown,
  },
  productContainer: {
    width: "80%",
    borderRadius: 10,
    marginBottom: 4,
    backgroundColor: Colors.light.navy,
  },
  productContainerOther: {
    width: "60%",
    borderRadius: 10,
    marginBottom: 4,
    backgroundColor: Colors.light.messageOther,
  },
  productTitle: {
    fontFamily: "spectral-700",
    fontSize: 16,
    marginLeft: 4,
    color: "#fff",
    paddingRight: 0,
  },
  productMetaData: {
    fontFamily: "spectral-600",
    fontSize: 14,
    color: "#fff",
    paddingLeft: 8,
  },
  productRow: {
    flexDirection: "row",
    backgroundColor: "transparent",
    padding: 10,
    paddingBottom: 0,
  },
  variantContainer: {
    padding: 10,
    paddingTop: 0,
    marginLeft: 10,
    backgroundColor: "transparent",
  },
  quantity: {
    backgroundColor: Colors.light.green,
    marginLeft: 10,
    borderRadius: 4,
    paddingHorizontal: 8,
    borderWidth: 2,
    borderColor: Colors.light.background,
  },
  space: {
    height: 20,
  },
  detailsContainer: {
    width: "100%",
  },
});
